import React, { useEffect } from "react";

import BouncerLogo from "../../assets/images/bouncer-logo2.svg";
// import createAccount from "../../assets/documentationAssets/create-account.png";
import verifyEmail from "../../assets/documentationAssets/verify-email.png";
import createOrganizationPage from "../../assets/documentationAssets/create-organization-page.png";
import createDevicePage from "../../assets/documentationAssets/create-device-page.png";
import addWebhookPage from "../../assets/documentationAssets/add-webhook-page.png";
import webhookPayload from "../../assets/documentationAssets/webhook-payload.png";
import integrationDiagram from "../../assets/documentationAssets/integration-diagram.png";
import stimulateScan from "../../assets/documentationAssets/stimulate-scan.png";
import downloadQr from "../../assets/documentationAssets/download-qr.png";
import manualVerification from "../../assets/documentationAssets/manual-verification.png";
import returnResponse from "../../assets/documentationAssets/return-response.png";
import qrInVendingMachine from "../../assets/documentationAssets/qrInVendingMachine.png";
import backButtonLogo from "../../assets/images/back-button.svg";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../LanguageSelect/LanguageSelect";

import "./WebhookIntegration.css";
const WebhookIntegration = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="WebhookIntegration">
      <div className="WebhookIntegration__container">
        <div className="Banner">
          <img
            onClick={() => {
              window.location.href = "https://www.bouncer.global/";
            }}
            className="BackButton"
            src={backButtonLogo}
          />
          <LanguageSelect />
        </div>
        <div className="Header">
          <div style={{ fontSize: "25px" }}>{t("webhookInt.mainTitle")}</div>
          <img
            style={{ width: "250px", cursor: "pointer" }}
            src={BouncerLogo}
          />
        </div>

        <div className="Subheader">
          <div style={{ fontSize: "25px", marginBottom: "30px" }}>
            {t("webhookInt.title")}
          </div>
          <div
            className="SubheaderParagraph"
            style={{ fontSize: "18px", textAlign: "center" }}
          >
            {t("webhookInt.subtitle")}
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step1")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step1bTitle")}
            </div>
          </div>

          {/* <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={createAccount} />
          </div> */}
          <div
            data-i18n="[html]webhookInt.step1Description"
            dangerouslySetInnerHTML={{
              __html: t("webhookInt.step1Description", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step2")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step2Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={verifyEmail} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step3")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step3Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={createOrganizationPage}
            />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step4")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step4Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={createDevicePage}
            />
          </div>
        </div>

        <div className="StepSecondLayout">
          <div>
            <div className="StepSecondLayout-number">
              {t("webhookInt.step5")}:
            </div>
            <div className="StepSecondLayout-title">
              {t("webhookInt.step5Title")}
            </div>
          </div>

          <div className="StepSecondLayout-content">
            <img className="StepSecondLayout-content-img" src={downloadQr} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step6")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step6Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={qrInVendingMachine}
            />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step7")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step7Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={addWebhookPage} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step8")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step8Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div
              className="StepFirstLayout-content-img"
              style={{ fontSize: "18px", color: "black" }}
              data-i18n="[html]webhookInt.step8Description"
              dangerouslySetInnerHTML={{
                __html: t("webhookInt.step8Description", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webhookInt.step9")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webhookInt.step9Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div
              className="StepFirstLayout-content-img"
              style={{ fontSize: "18px", color: "black" }}
            >
              <div>{t("webhookInt.step9Description1")}</div>
              <div
                data-i18n="[html]webhookInt.step9Description2"
                dangerouslySetInnerHTML={{
                  __html: t("webhookInt.step9Description2", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />

              <div
                data-i18n="[html]webhookInt.step9Description3"
                dangerouslySetInnerHTML={{
                  __html: t("webhookInt.step9Description3", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />

              <div
                data-i18n="[html]webhookInt.step9Description4"
                dangerouslySetInnerHTML={{
                  __html: t("webhookInt.step9Description4", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
              <div
                style={{
                  padding: "10px 20px",
                  background: "#1E2945",
                  color: "white",
                  borderRadius: "15px",
                  fontSize: "16px",
                  wordBreak: "break-word",
                  marginTop: "20px",
                }}
              >
                sign=2e1b8ae479a5a4b35fd2627797c3ffff446de989b8c9eeacc43c18185586e1cf
              </div>
            </div>
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webhookInt.step10")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step10Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div
              className="StepFirstLayout-content-img"
              style={{ fontSize: "18px", color: "black" }}
            >
              <div
                data-i18n="[html]webhookInt.step10Description1"
                dangerouslySetInnerHTML={{
                  __html: t("webhookInt.step10Description1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
              <br />
              <div
                data-i18n="[html]webhookInt.step10Description2"
                dangerouslySetInnerHTML={{
                  __html: t("webhookInt.step10Description2", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </div>
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webhookInt.step11")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webhookInt.step11Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <p>{t("webhookInt.step11Description")}</p>
            <img
              className="StepFirstLayout-content-img"
              src={manualVerification}
            />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webhookInt.step12")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webhookInt.step12Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <p>{t("webhookInt.step12Description")}</p>
            <img className="StepFirstLayout-content-img" src={webhookPayload} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webhookInt.step13")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webhookInt.step13Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div>
              <p>{t("webhookInt.step13Description1")}</p>
              <p
                data-i18n="[html]webhookInt.step13Description2"
                dangerouslySetInnerHTML={{
                  __html: t("webhookInt.step13Description2", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </div>

            <img className="StepFirstLayout-content-img" src={returnResponse} />
          </div>
        </div>

        <div className="StepSecondLayout">
          <div>
            <div className="StepSecondLayout-number">
              {" "}
              {t("webhookInt.step14")}:
            </div>
            <div className="StepSecondLayout-title">
              {" "}
              {t("webhookInt.step14Title")}:
            </div>
          </div>

          <div className="StepSecondLayout-content">
            <img className="StepSecondLayout-content-img" src={stimulateScan} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webhookInt.step15")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={integrationDiagram}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebhookIntegration;
