import React, { useEffect } from "react";

import BouncerLogo from "../../assets/images/bouncer-logo2.svg";
// import createAccount from "../../assets/documentationAssets/create-account.png";
import verifyEmail from "../../assets/documentationAssets/verify-email.png";
import createOrganizationPage from "../../assets/documentationAssets/create-organization-page.png";
import secureKey from "../../assets/documentationAssets/secure-key.png";
import verificationDiagram from "../../assets/documentationAssets/verification-diagram.jpg";
import backButtonLogo from "../../assets/images/back-button.svg";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { Trans, useTranslation } from "react-i18next";

import "./WebBasedIntegration.css";

const PORTAL_URL = "https://portal.bouncer.global";

const WebBasedIntegration = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const urlString =
    "https://age.bouncer.global/?session_token={{session_token}}&redirect_url=https://your.website/verifications";
  const urlStringTwo =
    "https://your.website/verifications?results_token={{results_token}}";

  return (
    <div className="WebBasedIntegration">
      <div className="WebBasedIntegration__container">
        <div className="Banner">
          <img
            onClick={() => {
              window.location.href = "https://www.bouncer.global/";
            }}
            className="BackButton"
            src={backButtonLogo}
          />
          <LanguageSelect />
        </div>
        <div className="Header">
          <div style={{ fontSize: "25px" }}>{t("webBasedInt.mainTitle")}</div>
          <img
            style={{ width: "250px", cursor: "pointer" }}
            src={BouncerLogo}
          />
        </div>

        <div className="Subheader">
          <div style={{ fontSize: "25px", marginBottom: "30px" }}>
            {t("webBasedInt.title")}
          </div>
          <div
            className="SubheaderParagraph"
            style={{ fontSize: "18px", textAlign: "center" }}
          >
            {t("webBasedInt.subtitle")}
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webBasedInt.step1")}:
            </div>
            <div className="StepFirstLayout-title">
                <Trans components={{ redirect: <a target="_blank" href={ PORTAL_URL } /> }}>
                    {t("webBasedInt.step1bTitle")}
                </Trans>
            </div>
          </div>

          {/* <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={createAccount} />
          </div> */}
          <div
            data-i18n="[html]webBasedInt.step1Description"
            dangerouslySetInnerHTML={{
              __html: t("webBasedInt.step1Description", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webBasedInt.step2")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webBasedInt.step2Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img className="StepFirstLayout-content-img" src={verifyEmail} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webBasedInt.step3")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webBasedInt.step3Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={createOrganizationPage}
            />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webBasedInt.step4")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webBasedInt.step4Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div>{t("webBasedInt.step4Description")}</div>
            <img className="StepFirstLayout-content-img" src={secureKey} />
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webBasedInt.step5")}:
            </div>
            <div className="StepFirstLayout-title">
              {" "}
              {t("webBasedInt.step5Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div
              className="StepFirstLayout-content-img"
              style={{ fontSize: "18px", color: "black" }}
            >
              <div
                data-i18n="[html]webBasedInt.step5Description1"
                dangerouslySetInnerHTML={{
                  __html: t("webBasedInt.step5Description1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />

              <br />
              <div style={{ fontWeight: "bold" }}>
                {t("webBasedInt.step5Description2")}
              </div>
              <br />
              <div
                data-i18n="[html]webBasedInt.step5Description3"
                dangerouslySetInnerHTML={{
                  __html: t("webBasedInt.step5Description3", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
              <br />
              <div>{t("webBasedInt.step5Description4")}</div>
              <br />
              <div>{t("webBasedInt.step5Description5")}</div>
              <code className="code">{t("webBasedInt.step5Description6")}</code>
            </div>
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webBasedInt.step6")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webBasedInt.step6Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div
              className="StepFirstLayout-content-img"
              style={{ fontSize: "18px", color: "black" }}
            >
              <div
                data-i18n="[html]webBasedInt.step6Description1"
                dangerouslySetInnerHTML={{
                  __html: t("webBasedInt.step6Description1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />

              <br />
              <div>
                {t("webBasedInt.step6Description2")}
                <br />
                <span
                  style={{
                    textDecoration: "underline",
                    fontSize: "12px",
                    color: "#595959",
                    backgroundColor: "#B7B7B7",
                    fontWeight: "600",
                    padding: "10px",
                    wordBreak: "break-word",
                    width: "fit-content",
                  }}
                >
                  {urlString}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {t("webBasedInt.step7")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webBasedInt.step7Title")}:
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div
              className="StepFirstLayout-content-img"
              style={{ fontSize: "18px", color: "black" }}
            >
              <div
                data-i18n="[html]webBasedInt.step7Description1"
                dangerouslySetInnerHTML={{
                  __html: t("webBasedInt.step7Description1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
              <br />
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "12px",
                  color: "#595959",
                  backgroundColor: "#B7B7B7",
                  fontWeight: "600",
                  padding: "10px",
                  wordBreak: "break-word",
                  width: "fit-content",
                }}
              >
                {urlStringTwo}
              </div>
              <br />

              <div
                data-i18n="[html]webBasedInt.step7Description2"
                dangerouslySetInnerHTML={{
                  __html: t("webBasedInt.step7Description2", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </div>
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-number">
              {" "}
              {t("webBasedInt.step8")}:
            </div>
            <div className="StepFirstLayout-title">
              {t("webBasedInt.step8Title")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <div
              className="StepFirstLayout-content-img"
              style={{ fontSize: "18px", color: "black" }}
            >
              <div
                data-i18n="[html]webBasedInt.step8Description1"
                dangerouslySetInnerHTML={{
                  __html: t("webBasedInt.step8Description1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
              <br />
              <div>{t("webBasedInt.step8Description2")}</div>
              <br />
              <div>{t("webBasedInt.step8Description3")}</div>
              <br />
              <ul className="validate-jwt">
                <li>
                    <Trans components={{ redirect: <a target="_blank" href={ PORTAL_URL } /> }}>
                        {t("webBasedInt.step8Description4")}
                    </Trans>
                </li>
                <li>
                    <Trans components={{ redirect: <a target="_blank" href="https://jwt.io/" /> }}>
                        {t("webBasedInt.step8Description5")}
                    </Trans>
                </li>
                <li>
                    <Trans components={{ strong: <strong /> }}>
                        {t("webBasedInt.step8Description6")}
                    </Trans>
                </li>
                <li>
                    <Trans components={{ strong: <strong /> }}>
                        {t("webBasedInt.step8Description7")}
                    </Trans>
                </li>
                <li>
                    <Trans components={{ strong: <strong /> }}>
                        {t("webBasedInt.step8Description8")}
                    </Trans>
                </li>
              </ul>
              <br />
                <div>
                    <Trans components={{ redirect: <a target="_blank" href="https://jwt.io/" /> }}>
                    {t("webBasedInt.step8Description9")}
                    </Trans>
                </div>
            </div>
          </div>
        </div>

        <div className="StepFirstLayout">
          <div>
            <div className="StepFirstLayout-title">
              {t("webBasedInt.step9")}
            </div>
          </div>

          <div className="StepFirstLayout-content">
            <img
              className="StepFirstLayout-content-img"
              src={verificationDiagram}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebBasedIntegration;
